<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('Accounts')"
    @cancel="onCancel('Accounts')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'AccountCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'Accounts',
      pageHeader: 'Создание аккаунта',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Аккаунты',
          route: { name: 'Accounts' },
        },
        {
          text: 'Создание аккаунта',
        },
      ],
      initialForm: {
        fio: null,
        position: null,
        phone: null,
        mail: null,
        // verificatedPhone: false,
        // isPhoneVerificated: false,
        login: null,
        password: null,
        isUseChatBot: false,
        comment: null,
        created: null,
        // isPool: false,
      },
      form: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'fio',
              label: 'ФИО',
            },
            {
              type: 'text',
              key: 'login',
              label: 'Логин (телефон)',
            },
            {
              type: 'text',
              key: 'phone',
              label: 'Дополнительные телефоны (заполнять через ;)',
            },
            {
              type: 'text',
              key: 'password',
              label: 'Пароль',
            },
            {
              type: 'text',
              key: 'mail',
              label: 'Email',
            },
            {
              type: 'date',
              key: 'created',
              label: 'Создан',
            },
            {
              type: 'text',
              key: 'position',
              label: 'Должность',
            },
            {
              type: 'text',
              key: 'comment',
              label: 'Комментарий',
            },
            {
              type: 'checkbox',
              key: 'isUseChatBot',
              label: 'Использовать чат-бота WhatsApp',
            },
          ],
        },
        {
          tab: 'Параметры для сервиса UIS',
          form: [
            {
              type: 'text',
              key: 'uisToken',
              label: 'Ключ (генерируется в личном кабинете UIS)',
            },
            {
              type: 'text',
              key: 'uisVirtualPhone',
              label: 'Виртуальный номер, арендуемый клиентом',
            },
            // {
            //   type: 'text',
            //   key: 'uisOperatorPhone',
            //   label: 'Телефон оператора',
            // },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions(['createItem']),
  },
};
</script>
